import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import MNlogo from "../../assets/png/mulogo.png";
import { responsiveDimension } from "../../utils/Responsive/Responsivedimension";
import { ReactComponent as Call } from "../../assets/svg/callsvg.svg";
import { useSelector } from "react-redux";

const Navbar = () => {
  const Navigate = useNavigate();
  const locationId = useSelector((state) => state?.servicetype?.locationId);
  return (
    <AppBar
      color="background.paper"
      sx={{
        width: "100%",
        height: "4rem",
        zIndex: 1000,
        position: "sticky",
        bgcolor: "background.default",
      }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => Navigate(`/?locationid=${locationId}`)}
            sx={{
              width: responsiveDimension("100%", "100%", "25%", "20%", "20%"),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img src={MNlogo} alt="MNlogo" width={"100%"} />
          </Box>
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <Typography variant="body2" color="#0080DC">
              Help
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <Call />
              <Typography variant="body2" color="#0080DC">
                751-989-6800
              </Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
