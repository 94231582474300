import { createSlice } from "@reduxjs/toolkit";

const ServiceTypeSlice = createSlice({
  name: "servicetype",
  initialState: {
    servicetype: null,
    location: null,
    locationId: null,
    gender: [
      { gender: "Male", checked: true, value: "M" },
      { gender: "Female", checked: true, value: "F" },
    ],
    postalCode: null,
    insurance: null,
    distance: { from: 0, to: 30 },
  },
  reducers: {
    ServiceTypeValue: (state, action) => {
      console.log(action.payload);
      state.servicetype = action.payload;
    },
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    updateGender: (state, action) => {
      state.gender = action.payload;
    },
    updateLocationId: (state, action) => {
      state.locationId = action.payload;
    },
    updatePostalCode: (state, action) => {
      state.postalCode = action.payload;
    },
    updateInsurance: (state, action) => {
      state.insurance = action.payload;
    },
    updateDistance: (state, action) => {
      state.distance = action.payload;
    },
  },
});

export default ServiceTypeSlice.reducer;
export const {
  ServiceTypeValue,
  updateLocation,
  updateGender,
  updateLocationId,
  updatePostalCode,
  updateInsurance,
  updateDistance,
} = ServiceTypeSlice.actions;
