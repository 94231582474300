import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#144F79", // Green-500
    },
    secondary: {
      main: "#437294", // Green-400
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
      primary: "#E6F6FE",
      secondary: "#000000",
    },
    text: {
      primary: "#000000",
      secondary: "#555555",
      default: "#959595",
      navText: "#0080DC",
      laylineblue: "#215E9B",
      lightgray: "#4F4F51",
    },
    border: {
      default: "#CECECE",
      primary: "#144F79",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontSize: "1rem",
      textTransform: "none", // Disable uppercase
      fontWeight: 500,
      // background: "linear-gradient(90deg, #0074C6 0%, #144F79 100%)",
      boxShadow: "none", // Remove the default box shadow
      borderRadius: "18px",
      backgroundColor: "red",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
  },
});

export default lightTheme;
