import { createSlice } from "@reduxjs/toolkit";

const patientSlice = createSlice({
  name: "patientdetails",
  initialState: {
    patients: [],
    patientdetail: [],
    selectedProvider: null,
    isExistingPatient: false,
    insurancePolicy: null,
    selectedTime: null,
  },
  reducers: {
    patientValue: (state, action) => {
      state.patients = action.payload;
    },
    removepatientValue: (state, action) => {
      state.patients = [];
      state.patients = [];
      state.patientdetail = [];
      state.selectedProvider = null;
      state.isExistingPatient = false;
      state.insurancePolicy = null;
      state.selectedTime = null;
    },
    patientdetailsValue: (state, action) => {
      state.patientdetail = action.payload;
    },
    updateSelectedProvider: (state, action) => {
      state.selectedProvider = action.payload;
    },
    updateselectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    updateIsExistingPatient: (state, action) => {
      state.isExistingPatient = action.payload;
    },
    updateInsurancePolicy: (state, action) => {
      state.insurancePolicy = action.payload;
    },
  },
});

export default patientSlice.reducer;
export const {
  patientValue,
  updateInsurancePolicy,
  updateselectedTime,
  patientdetailsValue,
  updateSelectedProvider,
  updateIsExistingPatient,
  removepatientValue,
} = patientSlice.actions;
