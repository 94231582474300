import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import routes from "./routes/routes";
import ThemeProvider from "./theme/ThemeProvider";
import { store, persistor } from "./Redux/Store/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";

const App = () => {
  return (
    <SnackbarProvider maxSnack={1}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider>
            <Router>
              <AppRoutes routes={routes} />
            </Router>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
