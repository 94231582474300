import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import stepperSlice from "../StepperSlice/stepperSlice";
import sessionStorage from "redux-persist/es/storage/session";
import ServiceTypeSlice from "../ServiceTypeSlice/ServiceTypeSlice";
import patientSlice from "../PatientSlice/PatientSlice";

const stepperConfig = {
  key: "stepper",
  storage: sessionStorage,
  whitelist: ["stepper"],
};
const servicetypeConfig = {
  key: "servicetype",
  storage: sessionStorage,
  whitelist: [
    "servicetype",
    "location",
    "gender",
    "locationId",
    "postalCode",
    "insurance",
    "distance",
  ],
};
const PatienDetailconfig = {
  key: "patientdetails",
  storage: sessionStorage,
  whitelist: [
    "patients",
    "selectedProvider",
    "isExistingPatient",
    "insurancePolicy",
    "selectedTime",
    "patientdetail",
  ],
};
const persistReducerStepper = persistReducer(stepperConfig, stepperSlice);
const persistServiceType = persistReducer(servicetypeConfig, ServiceTypeSlice);
const persistReducerPatient = persistReducer(PatienDetailconfig, patientSlice);

export const store = configureStore({
  reducer: {
    step: persistReducerStepper,
    servicetype: persistServiceType,
    Patientdetails: persistReducerPatient,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export const persistor = persistStore(store);
