import {
  IconButton,
  StepConnector,
  stepConnectorClasses,
  styled,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { responsiveDimension } from "../../utils/Responsive/Responsivedimension";

export default function HorizontalStepper({
  steps,
  accessKey = "name",
  stepValue = 0,
}) {
  const Navigation = useNavigate();
  const handleStepclick = (active, index) => {
    console.log(index, "-----");
    const path = steps?.[index]?.navigation;
    if (path && active) {
      Navigation(path);
    }
  };
  function ColorlibStepIcon(props, index) {
    const { active, icon, className } = props;
    return (
      <ActiveSteeper
        active={active}
        className={className}
        onClick={() => handleStepclick(active, index)}
      >
        <Typography>{icon}</Typography>
      </ActiveSteeper>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper alternativeLabel connector={<QontoConnector />}>
        {steps.map((label, index) => (
          <Step key={index} active={index <= stepValue}>
            <StepLabel StepIconComponent={(e) => ColorlibStepIcon(e, index)}>
              <Typography
                sx={{
                  display: responsiveDimension(
                    "none",
                    "none",
                    "inline",
                    "inline",
                    "inline"
                  ),
                }}
              >
                {label?.[accessKey]}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: "calc(-50% + 30px)",
    right: "calc(50% + 30px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.border.primary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.border.primary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.border.default,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const ActiveSteeper = styled(IconButton)(({ theme, active }) => ({
  width: 30,
  height: 30,
  backgroundColor: active ? theme.palette.primary.main : "transparent",
  display: "flex",
  border: `2px solid ${theme.palette.primary.main}`,
  alignItems: "center",
  justifyContent: "center",
  color: active ? "white" : "black",
  borderRadius: 1000,
  "&: hover ": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));
