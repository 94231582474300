import React, { Suspense } from 'react';
import Loader from '../components/common/Loader';

export const lazyLoad = (importFunc) => {
  const LazyComponent = React.lazy(importFunc);

  return (props) => (
    <Suspense fallback={<Loader text="Loading the component..." />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
