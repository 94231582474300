import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import HorizontalStepper from "./Stepper";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import { responsiveDimension } from "../../utils/Responsive/Responsivedimension";
import { useTheme } from "@emotion/react";

const Layout = ({ children }) => {
  const location = useLocation().pathname.toLocaleLowerCase();
  const isExistingPatient = useSelector(
    (state) => state.Patientdetails.isExistingPatient
  );
  const steps = !isExistingPatient
    ? [
        { name: "Location", navigation: "/" },
        { name: "  Choose Date & Time", navigation: "/provider" },
        { name: "Add Insurance", navigation: "/insurancePolicy" },
        { name: "Add Info", navigation: "/personalDetails" },
      ]
    : [
        { name: "Location", navigation: "/exiting-patient" },
        {
          name: "Choose Date & Time",
          navigation: "/exiting-patient/provider",
        },
        { name: "Add Insurance", navigation: "/insurancePolicy" },
        { name: "Add Info", navigation: "/personalDetails" },
      ];

  const stepValue = useSelector((state) => state?.step?.stepper);
  const theme = useTheme();

  // Use the theme's breakpoint instead of hardcoding the value
  const isExtreamSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediamScreen = useMediaQuery(theme.breakpoints.down("md"));

  const screen = isExtreamSmallScreen || isSmallScreen || isMediamScreen;

  return (
    <>
      <Navbar />
      {location !== "/" &&
        location !== "/exiting-patient" &&
        location !== "/appointment-success" &&
        location !== "/admin" && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            height={"10rem"}
            alignItems={"center"}
            // position={"sticky"}
            // top={0}
          >
            <Box
              sx={{
                width: responsiveDimension(
                  "100%",
                  "100%",
                  "100%",
                  "65%",
                  "65%"
                ),
              }}
            >
              <HorizontalStepper steps={steps} stepValue={stepValue} />
            </Box>
          </Box>
        )}
      <Box
        height={
          location !== "/" &&
          location !== "/exiting-patient" &&
          location !== "/appointment-success" &&
          location !== "/admin" &&
          !screen
            ? "calc(100vh - 14rem)"
            : "auto"
        }
        overflow={"auto"}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
