import React from 'react';
import { Navigate } from 'react-router-dom';

// Mock authentication function
const isAuthenticated = () => {
  // You can replace this with real authentication logic
  return false;
};

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
