import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#144F79", // Green-500
    },
    secondary: {
      main: "#437294", // Green-400
    },
    background: {
      default: "#121212",
      paper: "#1d1d1d",
      primary: "#E6F6FE",
      secondary: "#E6F6FE",
    },
    text: {
      primary: "#ffffff",
      secondary: "#bbbbbb",
      default: "#959595",
    },
    border: {
      default: "#CECECE",
      primary: "#144F79",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontSize: "1rem",
      textTransform: "none", // Disable uppercase
      fontWeight: 500,
      // background: "linear-gradient(90deg, #006CB9 0%, #144F79 100%)",
      boxShadow: "none", // Remove the default box shadow
      "&:hover": {
        boxShadow: "none", // Remove the box shadow on hover as well
      },
      "&:active": {
        boxShadow: "none", // Remove the box shadow when the button is active (clicked)
      },
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px', // Custom border radius
          textTransform: 'none', // Disable uppercase text
          padding: '10px 20px', // Custom padding
          boxShadow: 'none', // Remove default box shadow
        },
        containedPrimary: {
          background: 'linear-gradient(90deg, #006BB6 0%, #144F79 100%)', // Linear gradient background
          boxShadow: 'none', // No box shadow for contained button
          '&:hover': {
            background: 'linear-gradient(90deg, #0057a3 0%, #123c66 100%)', // Hover effect for gradient
            boxShadow: 'none', // Ensure no box shadow on hover
          },
        },
      },
    },
  },
});

export default darkTheme;
