import { createSlice } from "@reduxjs/toolkit";

const stepperSlice = createSlice({
  name: "stepper",
  initialState: {
    stepper: 0,
  },
  reducers: {
    stepperValue: (state, action) => {
      state.stepper = action.payload;
    },
  },
});

export default stepperSlice.reducer;
export const { stepperValue } = stepperSlice.actions;
