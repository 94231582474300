import { CssBaseline } from "@mui/material";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import darkTheme from "./darkTheme"; // Import dark theme
import lightTheme from "./lightTheme"; // Import light theme

const ThemeProvider = ({ children }) => {
  const [mode] = useState(
    localStorage.getItem("mode") === "dark" ? "dark" : "light"
  );

  // Toggle between light and dark themes
  const theme = useMemo(() => {
    return mode === "light" ? lightTheme : darkTheme;
  }, [mode]);

  // const toggleTheme = () => {
  //   const newMode = mode === "light" ? "dark" : "light";
  //   setMode(newMode);
  //   localStorage.setItem("mode", newMode);
  // };

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};

export default ThemeProvider;
