import { lazyLoad } from "../utils/lazyLoad";

const Home = lazyLoad(() => import("../pages/Home")); // Correct dynamic import
const AvailableSlot = lazyLoad(() => import("../pages/AvailableSlot"));
const Provider = lazyLoad(() => import("../pages/Provider"));
const PersonalDetailsform = lazyLoad(() =>
  import("../pages/PersonalDetailsform")
);
const InsurancePolicy = lazyLoad(() => import("../pages/InsurancePolicy"));
const ExitingPatientform = lazyLoad(() =>
  import("../pages/Home/ExitingPatientform")
);
const ExitingpatientProvider = lazyLoad(() =>
  import("../pages/Exitingpatient/index.js")
);
const SuccessScreen = lazyLoad(() =>
  import("../pages/SuccessScreen/SuccessScreen.js")
);
const AdminHome = lazyLoad(() => import("../pages/Admin/index.js"));
const routes = [
  {
    path: "/",
    element: Home,
    isProtected: false,
  },
  {
    path: "/provider",
    element: Provider,
    isProtected: false,
  },
  {
    path: "/slot",
    element: AvailableSlot,
    isProtected: false,
  },
  {
    path: "/personaldetails",
    element: PersonalDetailsform,
    isProtected: false,
  },
  {
    path: "/InsurancePolicy",
    element: InsurancePolicy,
    isProtected: false,
  },
  {
    path: "/exiting-patient",
    element: ExitingPatientform,
    isProtected: false,
  },
  {
    path: "/exiting-patient/provider",
    element: ExitingpatientProvider,
    isProtected: false,
  },
  {
    path: "/appointment-success",
    element: SuccessScreen,
    isProtected: false,
  },
  {
    path: "/admin",
    element: AdminHome,
    isProtected: false,
  },
];

export default routes;
