import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/common/Layout";
import ProtectedRoute from "../components/protected/ProtectedRoute";

const renderRoute = (route) => {
  const Element = route.isProtected ? (
    <ProtectedRoute>{<route.element />}</ProtectedRoute>
  ) : (
    <route.element />
  );

  if (route.children) {
    // Recursively render child routes
    return (
      <Route
        key={route.path}
        path={route.path}
        element={<Layout>{Element}</Layout>}
      >
        {route.children.map((childRoute) => renderRoute(childRoute))}
      </Route>
    );
  }

  return (
    <Route
      key={route.path}
      path={route.path}
      element={<Layout>{Element}</Layout>}
    />
  );
};

const AppRoutes = ({ routes }) => (
  <Routes>{routes.map((route) => renderRoute(route))}</Routes>
);

export default AppRoutes;
